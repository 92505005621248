import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Card from 'react-bootstrap/card';
import colorPaletteOutline from '../images/color-palette-outline.svg';
import colorPaletteGreyOutline from '../images/color-palette-grey.svg'
// import blurimg from '../images/blur.svg'
// import dotsimg from '../images/dots.svg'
// import dotssquareimg from '../images/dots-square.svg'



class AboutusSection extends React.Component {
  

    render() {
      return  <div id="aboutus" className="pt-3 position-relative overflow-hidden">

              {/* Floating icons */}
              {/* <span class"fas fa-times floating-icon color-purple roate-alternative"  style={{ top: '15%', right: '60px', fontSize: '60px'}}></span>
              <span className="fas fa-circle floating-icon  color-green beat-infinet" style={{ bottom: '120px', left: '25px', fontSize: '30px'}} ></span>
              <span className="far fa-square floating-icon  color-blue roate-infinet" style={{ top: '45%', left: '80px', fontSize: '20px'}}></span> */}

              {/* <span className="fas fa-play floating-icon  color-yellow roate-infinet" style={{fontSize: '60px', left: '30px', top: '5%'}}></span>

              <span className="fas fa-circle-notch floating-icon  color-red fs120 roate-infinet" style={{ bottom: '23%', right: '-50px'}}></span> */}



              {/* <img src={dotssquareimg} alt="floating-icons" className="floating-icon position-absolute" style={{width:'100px', left:'-5px', bottom: '50px'}} /> */}
              {/* <img src={blurimg} alt="floating-icons" className="floating-icon position-absolute" style={{width:'160px', left:'-7px', top: '330px'}} />

              <img src={dotsimg} alt="floating-icons" className="floating-icon position-absolute" style={{width:'160px', right:'-17px', bottom: '30px'}} /> */}


              

                <Container className="my-5" fluid>  
                <div className="text-center mb-5">
                    <div  className="position-relative section-title">
                        <h1 className="fs28 title">About Us</h1>
                        <span className="mirror-text">About Us</span>
                    </div>                    
                    <span className="fs16 color-grey3 pb-4 d-block"> We do what the world needs</span>

                <Row>
                  <Col xl={{ span: 5, offset: 1 }} className="smallerdesign">
                    
                      <ul className="d-flex about-us-design flex-nowrap justify-content-center row-1 mb-0">
                        <li>
                          <span className="lnr lnr-history"></span>
                          <div className="fs18 mt-4 heading">On Time</div>
                        </li>
                        <li>
                          <span className="lnr lnr-smile"></span>
                          <div className="fs18 mt-4 heading">User Friendly</div>
                        </li>
                        </ul>
                        <ul className="d-flex about-us-design justify-content-center flex-nowrap row-2">  
                        <li>
                          <span>₹</span>
                          <div className="fs18 mt-4 heading">Best in Market</div>
                        </li>
                        <li>
                          <img  src={colorPaletteOutline} alt="color-pallet"/>
                          <div className="fs18 mt-4 heading">Customizable</div>
                        </li>
                      </ul>
                  </Col>
                  <Col xl={5} md={10} className="mx-auto mt-5 mt-lg-0">
                     <p className="text-left fs14 color-grey3">
                       Veeyaa Innovatives meaning “Innovation with Perfection” 
                       which in turn describes “how we describe and express things in a Perfect manner in whatever we do and handle, 
                       leaving nothing merely implied”.
                     </p>
                     <p className="text-left fs14 color-grey3">
                       We are young developers who join hands together to make day today work simple for endusers.
                     </p>


                     <div className="d-flex align-items-center my-4 pt-3">
                       <div className="circle-count">
                        <span className="lnr lnr-history"></span>
                       </div>
                       <div className="text-left ml-3">
                         <h2 className="fs16 mb-1">On Time</h2>
                         <p className="fs14 mb-0 color-grey3">Ensuring high-quality products are hand over to our customers on-time.</p>
                       </div>
                     </div>
                     <div className="d-flex align-items-center my-4">
                       <div className="circle-count">
                        <span className="lnr lnr-smile"></span>
                       </div>
                       <div className="text-left ml-3">
                         <h2 className="fs16 mb-1">User Friendly</h2>
                         <p className="fs14 mb-0 color-grey3">Our mainly focused to design and develop the application based on the user Expectation.</p>
                       </div>
                     </div>

                     <div className="d-flex align-items-center my-4">
                       <div className="circle-count">
                       <span className="fs20">₹</span>
                       </div>
                       <div className="text-left ml-3">
                         <h2 className="fs16 mb-1">Best in Market</h2>
                         <p className="fs14 mb-0 color-grey3">
                           Our team analyse the fluctuation of the market price in local and international <br/> markets and ensured our customer to get more benefits.
                         </p>
                       </div>
                     </div>

                     <div className="d-flex align-items-center my-4">
                       <div className="circle-count">
                          <img  src={colorPaletteGreyOutline} alt="color-pallet"/>
                       </div>
                       <div className="text-left ml-3">
                         <h2 className="fs16 mb-1">Customizable</h2>
                         <p className="fs14 mb-0 color-grey3">
                         Based on the Technology trends and updates our software will always <br/> migratable &amp; upgradable
                         </p>
                       </div>
                     </div>
                     
                  </Col>
                </Row>


                </div>
                </Container>
              </div>;
    }
  }

export default AboutusSection;
