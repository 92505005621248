import React from 'react';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

class Footer extends React.Component {
    render() {
      return  <div className="mt-5">  
                {/* <Container fluid>
                  <Row>
                    <Col md={11} className="mx-auto"> */}
                      <footer >
                      
                        <div className="app-footer">
                            <h1 className="heading text-center fs28">Veeyaa Innovatives</h1>

                            <ul className="list-inline section-list text-center my-4">
                              <li className="list-inline-item"><a href="#home">Home</a></li>
                              <li className="list-inline-item"><a href="#aboutus">About Us</a></li>
                              <li className="list-inline-item"><a href="#services">Services</a></li>
                              <li className="list-inline-item"><a href="#ourprocess">Our Process</a></li>
                              <li className="list-inline-item"><a href="#gallert">Gallery</a></li>
                            </ul>

                            <ul className="list-inline text-center social-media mt-4 mb-0">
                              <li className="list-inline-item"><a href="#fb" className="fb"><em className="fab fa-facebook-f"></em></a></li>
                              <li className="list-inline-item"><a href="#ins" className="ins"><em className="fab fa-instagram"></em></a></li>
                              <li className="list-inline-item"><a href="#twt" className="twt"><em className="fab fa-twitter"></em></a></li>
                              <li className="list-inline-item"><a href="#gp" className="gp"><em className="fab fa-google-plus-g"></em></a></li>
                            </ul>


                        </div>
                        <div className="sub-footer text-center">
                          <p className="credits-text mb-0">Created with <em className="fas fa-heart beating"></em> By VeeyaaInnovatives</p>
                         </div>
                      </footer>
                    {/* </Col>
                  </Row>
                </Container> */}
             </div>;
    }
  }

export default Footer;
