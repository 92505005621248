import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/card';
import iphone from '../images/iphone.svg';
// import dotsimg from '../images/dots.svg'
class ServicesSection extends React.Component {
    // componentDidMount () {
    //     const script = document.createElement("script");
    
    //     script.src = "https://use.typekit.net/foobar.js";
    //     script.async = true;
    
    //     document.body.appendChild(script);
    // }

    render() {
      return  <div id="services" className="pt-3  position-relative overflow-hidden">

               {/* Floating Icons */}
              {/* <img src={blurimg} alt="floating-icons" className="floating-icon position-absolute" style={{width:'100px', left:'20px', bottom: '15%'}} />
              <img src={dotsimg} alt="floating-icons" className="floating-icon position-absolute" style={{width:'120px', right:'10px', top: '10%'}} />  */}
              {/* <span className="fas fa-times floating-icon color-purple roate-alternative"  style={{ top: '15%', right: '60px', fontSize: '60px'}}></span>
              <span className="fas fa-circle floating-icon  color-green beat-infinet" style={{ bottom: '120px', left: '25px', fontSize: '30px'}} ></span>
              <span className="far fa-square floating-icon  color-blue roate-infinet" style={{ top: '45%', left: '80px', fontSize: '20px'}}></span>        */}

                <Container className="my-5" fluid>  
                <div className="text-center mb-5">
                    <div  className="position-relative section-title">
                        <h1 className="fs28 title">Our Services</h1>
                        <span className="mirror-text">Our Services</span>
                    </div>                    
                    <span className="fs16 color-grey3">We innovate things with perfection</span>
                </div>
                    <Row>
                       <Col md={{span: 10, offset: 1}}>
                            <Row>
                                <Col  md={6} lg={4}>                                  
                                    <Card className="service-cards mb-4">
                                        <Card.Body>
                                            <div className="icon purple">
                                                <span className="lnr lnr-laptop-phone"></span>
                                            </div>
                                            <h2 className="fs22  mt-4">Design &amp; Development</h2>
                                             <p className="desc"> 
                                               Crafting World-Class Website Designs for Real World Experiences, transforming your ideas play into action
                                            </p> 
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col  md={6} lg={4}>
                                    <Card className="service-cards mb-4">
                                        <Card.Body>
                                            <div className="icon pink">
                                                <span className="lnr lnr-earth"></span>
                                            </div>
                                            <h2 className="fs22  mt-4"> Web Hosting &amp; Maintenance </h2>
                                             <p className="desc"> 
                                               Reliable Web Hosting and Maintenance ensures an intermittent online presence for the deployed software.
                                            </p> 
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col  md={6} lg={4}>
                                    <Card className="service-cards mb-4">
                                        <Card.Body>
                                            <div className="icon orange">
                                                <span className="lnr lnr-cog"></span>
                                            </div>
                                            <h2 className="fs22  mt-4">Web Development</h2>
                                             <p className="desc">  
                                                Delivering Customizable Web Development Solutions suitable to your business ideas.
                                            </p> 
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={6} lg={4}>
                                    <Card className="service-cards mb-4">
                                        <Card.Body>
                                            <div className="icon green">
                                               <img src={iphone} className="icon-img" alt="iphone"/>
                                            </div>
                                            <h2 className="fs22  mt-4"> Mobile App Development </h2>
                                             <p className="desc">  
                                               Deploying a rich and powerful Mobile App that drives your Mobile Savvy audience with your business.
                                            </p> 
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col  md={6} lg={4}>
                                    <Card className="service-cards mb-4">
                                        <Card.Body>
                                            <div className="icon blue">
                                                <span className="lnr lnr-cart"></span>
                                            </div>
                                            <h2 className="fs22  mt-4"> E-commerce</h2>
                                             <p className="desc">      
                                                Delivering High-performance, feature-rich Online Store that drive conversions and sales for your business.
                                            </p> 
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col  md={6} lg={4}>
                                    <Card className="service-cards mb-4">
                                        <Card.Body>
                                            <div className="icon navy-blue">
                                                <span className="lnr lnr-rocket"></span>
                                            </div>
                                            <h2 className="fs22  mt-4"> Search Engine Optimization </h2>
                                             <p className="desc">      
                                             With the power of SEO push your website to the front line of business war.
                                            </p> 
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                       </Col>
                   </Row>
                </Container>
              </div>;
    }
  }

export default ServicesSection;
