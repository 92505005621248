import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/card';
import arrow from '../images/curved-arrow-with-broken-line.svg'
class HowWeDoSection extends React.Component {
   
    render() {
      return  <div id="process" className="pt-3 position-relative overflow-hidden">

                {/* Floating Icons */}
                {/* <span className="fas fa-star floating-icon color-red roate-alternative" style={{fontSize: '50px', left: '30px', top: '5%'}}></span>

                <span className="fas fa-circle-notch floating-icon  color-blue fs120 roate-infinet" style={{ bottom: '120px', left: '-50px'}}></span>

                <span className="fas fa-star-of-life floating-icon  color-green fs120 roate-alternative" style={{ top: '45%', right: '60px', fontSize: '60px'}}></span>

                <span className="far fa-square floating-icon  color-purple roate-infinet" style={{ top: '15%', right: '60px', fontSize: '60px'}}></span>

                <span className="fas fa-play floating-icon  color-yellow roate-infinet" style={{ top: '45%', left: '80px', fontSize: '60px'}} ></span>

                <span className="fas fa-heart floating-icon  color-pink beat-infinet"  style={{ bottom: '23%', right: '40px', fontSize: '30px'}}></span> */}


                <Container className="my-5" fluid>  
                    <div className="text-center mb-5">
                        <div  className="position-relative section-title">
                            <h1 className="fs28 title">How We Do</h1>
                            <span className="mirror-text">How We Do</span>
                        </div>                    
                        <span className="fs16 color-grey3">these are our process to make your life easy</span>
                    </div>
                   


                    <Row>
                        <Col  md={9} className="mx-auto" lg={{span: 6, offset: 3}}>                                  
                            <Row className="position-relative">
                                <Col  md={6} className="position-relative">     
                                   <div className="h-100 position-absolute left-75p">
                                        <h1 className="mt-0 count-title step-1">
                                            <span className="pl-5 fs24">Step</span>    
                                            <br />
                                            <span className="step-count">01</span> 
                                        </h1>
                                    </div>                              
                                    <Card className="how-do-cards bg-transparent step-1-card">
                                        <Card.Body>
                                             <h2>Plan</h2>
                                             <p>
                                                 To help ensure you don’t miss any crucial steps in building your website, we device simple PLAN to create a App that will help you reach your home business goals.
                                             </p>
                                        </Card.Body>
                                    </Card>

                                    <img src={arrow} alt="broken-arrow" className="broken-arrow d-none d-md-block ml-auto" />
                                </Col>
                                <Col  md={6} className="position-relative">
                                    <div className="h-200px mt-5 d-none d-md-block"></div>
                                    <div className="h-100  position-absolute right-75p">
                                        <h1 className="mt-0 count-title  step-2">
                                            <span className="pl-5 fs24">Step</span>    
                                            <br />
                                            <span className="step-count">02</span> 
                                        </h1>
                                    </div>         
                                    <Card className="how-do-cards bg-transparent step-2-card">
                                        <Card.Body>
                                             <h2>Design</h2>
                                             <p>Web design is the process of planning, conceptualizing, and arranging content intended for the business. Web design is not limited to websites as it includes other uses such as web apps, mobile apps, and user interface design.</p>
                                     
                                        </Card.Body>
                                    </Card>
                                    <img src={arrow} alt="broken-arrow" className="broken-arrow d-none d-md-block mr-auto ryt position-absolute" />

                                </Col>
                          
                                <Col  md={6} className="position-relative">    
                                     <div className="mt-minus-5 d-none d-md-block"></div>
                                     <div className="h-100 position-absolute left-75p">
                                            <h1 className="mt-0 count-title  step-3">
                                                <span className="pl-5 fs24">Step</span>    
                                                <br />
                                                <span className="step-count">03</span> 
                                            </h1>
                                        </div>                              
                                    <Card className="how-do-cards  bg-transparent step-3-card">
                                        <Card.Body>
                                             <h2>Develop</h2>
                                             <p>We can build your site quickly and provide guidance on successful web design. Hiring a Us can be particularly useful if you are looking at having an online shop or offering other services through your website</p>
                                        </Card.Body>
                                    </Card>
                                    <img src={arrow} alt="broken-arrow" className="broken-arrow d-none d-md-block ml-auto" />
                                </Col>

                                <Col  md={6} className="position-relative">
                                    <div className="h-200px mt-4 d-none d-md-block"></div>
                                    <div className="h-100  position-absolute right-75p">
                                        <h1 className="mt-0 count-title  step-4">
                                            <span className="pl-5 fs24">Step</span>    
                                            <br />
                                            <span className="step-count">04</span> 
                                        </h1>
                                    </div>         
                                    <Card className="how-do-cards bg-transparent step-4-card">
                                        <Card.Body>
                                             <h2>Test</h2>
                                             <p>We make sure after developing application we run full round of testing to ensure that the application is bug free</p>
                                    
                                        </Card.Body>
                                    </Card>
                                    <img src={arrow} alt="broken-arrow" className="broken-arrow d-none d-md-block mr-auto ryt position-absolute" />
                                </Col>

                                       
                                <Col  md={6} className="position-relative">    
                                     <div className="mt-minus-5"></div>
                                     <div className="h-100 position-absolute left-75p">
                                            <h1 className="mt-0 count-title  step-5">
                                                <span className="pl-5 fs24">Step</span>    
                                                <br />
                                                <span className="step-count">05</span> 
                                            </h1>
                                        </div>                              
                                    <Card className="how-do-cards  bg-transparent step-5-card">
                                        <Card.Body>
                                            <h2>Deliver</h2>
                                             <p> After completing rigorous rounds testing process we will deliver you bug free &amp; best application to help your business growth </p>
                                    
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
              </div>;
    }
  }

export default HowWeDoSection;
