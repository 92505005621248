import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/card';
import projectsimg from '../images/projects-done.svg';
import smileimg from '../images/smile.svg';
import coffeeimg from '../images/coffee-breaks.svg';
// import underconstrunction from '../images/gfx-mobile-construction.svg';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';

class HomeSection extends React.Component {

    

  constructor(props)
  {
       super(props);
       this.state = {successmsg:"",errormsg:"", selectedclass:"custom-select border-0 bg-transparent form_fields lh-46"}
     }
        email_validation(email)
       {
           const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
           return re.test(String(email).toLowerCase());
       }
        name_validation(name)
       {
           var letters = /^([\s\.]?[a-zA-Z]+)+$/;
           if(name.match(letters))
                 {
               return true;
                 }
               else
                 {
                 return false;
                 }
       }

        mobile_number_validation(mobile)
       {
           var phoneno = /^\d{10}$/;
           if(mobile.match(phoneno))
                 {
               return true;
                 }
               else
                 {
                 return false;
                 }
       }


       set_error(fieldid)
       {
       //var div_error = $('#'+fieldid).closest('div[class^="input-group"]');

       //div_error.addClass('has-error');
       }

       alertmsg(message,focusid)
       {
         this.setState({ errormsg:message});
         if(focusid && focusid!=="")
         {
         document.getElementById(focusid).focus();
         }
       }

       successmsg(message)
       {
       this.setState({ successmsg:message});
       }

       serviceValidate = async (event) => {
       
     var service = document.getElementById('service').value;
     var classname="custom-select border-0 bg-transparent form_fields lh-46";
     if(service && service!=="")
     {         
         classname=classname+" selected";
     }

     this.setState({ selectedclass:classname});
     }

  getQuote = async (event) => {
     event.preventDefault();

     var self=this;
     self.setState({ errormsg:""});
     self.setState({ successmsg:""});

     var name = document.getElementById('name').value;

     var email = document.getElementById('email').value;

     var service = document.getElementById('service').value;

     var budget = document.getElementById('budget').value;

     var message = document.getElementById('message').value;

     var name_val= self.name_validation(name);
     //var mobile_val=self.mobile_number_validation(c_mobile);
     var email_val=self.email_validation(email);


       //has error validation
         if(!name || name==="")
         {
           self.set_error("name");
         }
         if(!name_val)
         {
           self.set_error("name");
         }
         if(!email || email==="")
         {
           self.set_error("email");
         }
         if(!email_val)
         {
           self.set_error("email");
         }
         if(!service || service==="")
         {
           self.set_error("service");
         }
         if(!budget || budget==="")
         {
           self.set_error("budget");
         }
         if(!message || message==="")
         {
           self.set_error("message");
         }
       //has error validation


         if(!name || name==="")
         {
         self.alertmsg("Name is required","name");
         }
         else if(!name_val)
         {
         self.alertmsg("Type valid name","name");
         }
         else if(!email || email==="")
         {
         self.alertmsg("Email id is required","email");
         }
         else if(!email_val)
         {
         self.alertmsg("Type valid email id","email");
         }
         else if(!service || service==="")
         {
         self.alertmsg("Service is required","service");
         }
         else if(!budget || budget==="")
         {
         self.alertmsg("Budget is required","budget");
         }
         else if(!message || message==="")
         {
         self.alertmsg("Message is required","message","message");
         }
         else
         {
           //var site_url="http://localhost/veeyaa_site/veeyaaapi.php";
          var site_url="https://veeyaainnovatives.com/veeyaaapi";
          site_url=site_url+"?work=getquote&name="+name+"&email="+email+"&service="+service+"&budget="+budget+"&message="+message;
       axios.get(site_url)
     .then(res => {
     this.setState({loading : false});
     const table_data = res.data;
     var response=table_data[0].response;
     var reason=table_data[0].reason;
       if(response==="success")
       {
         self.successmsg("Quote sent successfully,We will contact you shortly");
         document.getElementById("quoteForm").reset();

         var classname="custom-select border-0 bg-transparent form_fields lh-46";    
         self.setState({ selectedclass:classname});
         
       }
       else
       {
         self.alertmsg(reason,"");
       }
     })
     }
   };

    render() {
      return  <div id="home">
                {/* <div className="d-xl-none position-fixed bg-white construnction text-center justify-content-center" >
                    <div>
                    <img src={underconstrunction} alt="under construction" className="mb-4"/>
                    <h1 className="fs28">This View is Under Construction</h1>
                    <span>At the moment best to view this site in Desktop or Laptop</span>
                    </div>
                </div> */}
          <Carousel fade={true} className="home-carousel" indicators={false} controls={false}>
            <Carousel.Item>
                <div className="cover-img position-relative" style={{ background: 'rgb(13, 95, 255)'}} >
                    {/* backgroundImage: 'url('+ bannerOne +')', */}

                    {/* Design */}
                    <div className="d-flex">
                        <div className="design-1"></div>
                        <div className="design-2"></div>
                        <div className="design-3"></div>
                        <div className="design-4"></div>
                        <div className="design-5"></div>
                        <div className="design-6"></div>
                        <div className="design-7"></div>
                    </div>


                    <div className="overlay">
                      {/* h-41rem */}
                      <Container fluid className="py-5">
                      <Row className="h-100 mt-3">
                            <Col md={10}  className="mx-auto">
                      <Row className="h-100">
                          <Col md={12} lg={6}   className="d-flex align-items-center justify-content-center pr-md-0">
                              <div className="text-white mt-5">
                                <span className="text-uppercase fs12  sub-title">We are Veeyaa Innovatives</span>
                                <h1>Create a Great Website Fast With Us</h1>

                                <Row className="my-5 d-none d-lg-flex">
                                               <Col md={4}>
                                                   <div className="d-flex align-items-center h-100">
                                                        <img src={projectsimg} className="stats-icon" alt="projects" />
                                                        <div className="pl-3">
                                                            <h2 className="mb-0 fs22">30</h2>
                                                            <span className="fs14">No Of Projects</span>
                                                        </div>
                                                   </div>
                                               </Col>
                                               <Col md={4} className="border-left border-right pl-4">
                                                    <div className="d-flex align-items-center h-100">
                                                        <img src={smileimg} className="stats-icon" alt="projects" />
                                                        <div className="pl-3">
                                                            <h2 className="mb-0 fs22">30</h2>
                                                            <span className="fs14">Happy Faces</span>
                                                        </div>
                                                   </div>
                                               </Col>
                                               <Col md={4} className="pl-4">
                                                    <div className="d-flex align-items-center h-100">
                                                        <img src={coffeeimg} className="stats-icon" alt="projects" />
                                                        <div className="pl-3">
                                                            <h2 className="mb-0 fs22">100</h2>
                                                            <span className="fs14">Cup of Coffee</span>
                                                        </div>
                                                   </div>
                                               </Col>
                                           </Row>


                                <a href="mailto:Contactus@veeyaainnovatives.com" className="text-white"><em className="fas fa-envelope-open-text mr-2"></em> Contactus@veeyaainnovatives.com</a>

                                {/* <button className="btn btn-cards br-20px  px-4">Contact Us</button> */}
                             </div>
                          </Col>
                          <Col md={12} lg={5} className="d-flex align-items-center justify-content-center">

                            <Card className="get-a-quote-card bg-white border-0 w-100 mt-5">
                                <Card.Header className="bg-white border-0 pb-0 pt-3 px-4">
                                    <h1 className="fs18 font-title op-9 mb-1 pt-2">Get a Quote</h1>
                                    <span className="color-grey3 fs11 d-block">We will reach you in notime</span>
                                </Card.Header>
                                <Card.Body className="px-4 pb-4 pt-3 ">
                            
                                    <form className="custom-input" onSubmit={this.getQuote} id="quoteForm">
                                        <div className="input-group floating-input mb-3">
                                            <input type="text"  id="name"className="form-control border-0 bg-transparent pt-0 form_fields" placeholder=" "/>
                                            <label className="floating-lable d-block position-absolute px-2 pt-0 bg-white">Name</label>
                                        </div>
                                        <div className="input-group floating-input mb-3">
                                            <input type="text" id="email" className="form-control border-0 bg-transparent pt-0 form_fields" placeholder=" "/>
                                            <label className="floating-lable d-block position-absolute px-2 pt-0 bg-white">Email</label>
                                        </div>
                                        <div className="input-group floating-input mb-3">
                                            <select className={this.state.selectedclass} onChange={this.serviceValidate} id="service">
                                               <option val=""></option>
                                               <option val="Mobile App Development">Mobile App Development</option>
                                               <option val="Website Development">Website Development</option>
                                               <option val="Shopping Website">Shopping Website</option>
                                               <option val="Inventory Management system">Inventory Management system</option>
                                            </select>
                                            <label className="floating-lable d-block position-absolute px-2 pt-0 bg-white">Service you need</label>
                                        </div>
                                        <div className="input-group floating-input mb-3">
                                            <input type="number" id="budget" className="form-control border-0 bg-transparent pt-0 form_fields" placeholder=" "/>
                                            <label className="floating-lable d-block position-absolute px-2 pt-0 bg-white">Your budget</label>
                                        </div>
                                        <div className="input-group floating-input textarea-form-input mb-3 h-auto">
                                            <textarea id="message" className="form-control border-0 bg-transparent  form_fields" rows="3" placeholder=" "/>
                                            <label className="floating-lable d-block position-absolute px-2 pt-0 bg-white">Message</label>
                                        </div>
                                       
                                        <div className="fs14 mb-3 text-center">
                                            <span className="text-danger">{this.state.errormsg}</span>
                                            <span className="text-success">{this.state.successmsg}</span>
                                        </div>
      
                                        <Row className="text-center">
                                            <Col md={{span:6, offset:3}}>
                                                <button type="submit" className="btn btn-quote btn-block">
                                                    Get Quote
                                                </button>
                                            </Col>
                                        </Row>
                                    </form>
                                </Card.Body>
                            </Card>
                          </Col>

                      </Row>
                      </Col>
                      </Row>
                      </Container>

                        {/* <Carousel.Caption className="pb-0">
                            <Row>
                                <Col md={{span: 8 , offset: 2}}>
                                    <Card className="text-blac bg-transparent border-0">
                                        <Card.Body className="p-3">
                                           <Row>
                                               <Col md={4}>
                                                   <h2 className="mb-1">30</h2>
                                                   <span>No Of Projects</span>
                                               </Col>
                                               <Col md={4} className="px-0">
                                                   <div  className="border-left border-right">
                                                   <h2 className="mb-1">30</h2>
                                                   <span>Happy Faces</span>
                                                   </div>
                                               </Col>
                                               <Col md={4}>
                                                   <div className="my-3">
                                                        <button className="btn btn-cards br-20px">Get a Quote</button>
                                                   </div>
                                               </Col>
                                           </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Carousel.Caption> */}
                    </div>
                </div>
            </Carousel.Item>
            {/* <Carousel.Item>
                <div className="cover-img" style={{backgroundImage: 'url("https://images.unsplash.com/photo-1551732998-9573f695fdbb?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80")'}} >
                    <div className="overlay">
                        <Carousel.Caption>
                            <h3>second slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="cover-img" style={{backgroundImage: 'url("https://images.unsplash.com/photo-1551732998-9573f695fdbb?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80")'}} >
                    <div className="overlay">

                        <Carousel.Caption>
                            <h3>third slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                        </Carousel.Caption>
                    </div>
                </div>
            </Carousel.Item> */}
            </Carousel>
              </div>;
    }
  }

export default HomeSection;
