import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Card from 'react-bootstrap/card';
import galleryimgOne from '../images/banner-1.jpg';
import galleryimgTwo from '../images/careers.jpg';
import galleryimgThree from '../images/contact-us.jpg';
import galleryimgFour from '../images/gallery.jpg'
class GallerySection extends React.Component {
    
    render() {
      return  <div id="gallery" className="pt-3  position-relative overflow-hidden">

                <Container className="my-5" fluid>  
                <div className="text-center mb-5">
                    <div  className="position-relative section-title">
                        <h1 className="fs28 title">Gallery</h1>
                        <span className="mirror-text">Gallery</span>
                    </div>                    
                    <span className="fs16 color-grey3">We innovate things with perfection</span>
                </div>

               <Row>
                   <Col md={11} lg={9} className="mx-auto">
                   <div className="d-flex gallery">
                        <div className="galleryItem" style={{ backgroundImage: 'url('+ galleryimgOne +')'}}>
                            <div className="overlay">
                                <div>
                                    <h1 className="gallery-title text-nowrap">Mobile App Development</h1>    
                                    <span className="gallery-desc text-nowrap">We Develop both Hybrid &amp; Native apps</span> 
                                </div>   
                            </div>
                        </div>
                        <div className="galleryItem" style={{ backgroundImage: 'url('+ galleryimgTwo +')'}}>
                            <div className="overlay">
                                <div>
                                    <h1 className="gallery-title text-nowrap">Web App Development</h1>    
                                    <span className="gallery-desc text-nowrap">With latest technologies We develop best website suited for your business</span> 
                                </div>   
                            </div>
                        </div>
                        <div className="galleryItem" style={{ backgroundImage: 'url('+ galleryimgThree +')'}}>
                             <div className="overlay">
                                <div>
                                    <h1 className="gallery-title text-nowrap">We Support</h1>    
                                    <span className="gallery-desc text-nowrap">You can reach out us any time you need</span> 
                                </div>   
                            </div>
                        </div>
                        <div className="galleryItem" style={{ backgroundImage: 'url('+ galleryimgFour +')'}}>
                            <div className="overlay">
                                <div>
                                    <h1 className="gallery-title text-nowrap">Business Consultant</h1>    
                                    <span className="gallery-desc text-nowrap">We provide best suited software package to grow your business</span> 
                                </div>   
                            </div>
                        </div>
                    </div>

                   </Col>
               </Row>
                        
                </Container>
              </div>;
    }
  }

export default GallerySection;
