import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/row';
import Col from 'react-bootstrap/col';
import Container from 'react-bootstrap/container';
import { Link, animateScroll as scroll } from "react-scroll";

class Header extends React.Component {


  componentDidMount() {
    window.addEventListener("scroll", this.toggleBodyClass);
    this.toggleBodyClass();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    if (window.scrollY < 100) {
      document.getElementById("header").classList.add('navtop');
      document.getElementById("header").classList.remove('navscroll');
    } else {
      document.getElementById("header").classList.remove('navtop');
      document.getElementById("header").classList.add('navscroll');
    }
  };

  render() {

    return <div>
      <Navbar className="custom-navbar navtop" collapseOnSelect expand="lg" id="header">
        <Container fluid>
          <Row className="w-100">
            <Col md={{ span: 10, offset: 1 }} className="px-lg-0 pr-0">
              <div className="d-lg-flex">
                <div className="d-flex">
                  <Navbar.Brand href="#home" className="d-inline-block heading">Veeyaa Innovatives</Navbar.Brand>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" className="ml-auto" />
                </div>
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="ml-auto">
                    <Link className="nav-link"
                      activeClass="active"
                      to="home"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >Home
                                        </Link>
                    <Link
                      className="nav-link"
                      activeClass="active"
                      to="aboutus"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}>
                      About Us
                                        </Link>
                    <Link className="nav-link"
                      activeClass="active"
                      to="services"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}>
                      Services
                                        </Link>

                    <Link className="nav-link"
                      activeClass="active"
                      to="process"
                      spy={true}
                      smooth={true}
                      offset={-20}
                      duration={500}>Our Process
                    </Link>
                    <Link className="nav-link" 
                      activeClass="active"
                      to="gallery"
                      spy={true}
                      smooth={true}
                      offset={-20}
                      duration={500}
                    >Gallery</Link>

                  </Nav>
                </Navbar.Collapse>
              </div>
            </Col>
          </Row>
        </Container>

      </Navbar>
    </div>;
  }
}

export default Header;
