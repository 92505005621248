import React from 'react';
import './App.scss';

import Header from './layout/header';
import Footer from './layout/footer';
import HomeSection from './page-sections/home-section';
import AboutusSection from './page-sections/aboutus-section';
import ServicesSection from './page-sections/services-section';
import HowWeDoSection from './page-sections/how-we-do-section';
import GallerySection from './page-sections/gallery-section';

function App() {
  return (
    <div>
      <Header />
      <HomeSection />
      <AboutusSection />
      <ServicesSection />
      <HowWeDoSection />
      <GallerySection />
      <Footer />
    </div>
  );
}

export default App;
